export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#210328',
  'gradient-secondary': '#210328',

  'primary-color': '#A0BD42',
  'secondary-color': '#A0BD42',
  'auxiliary-color': '#6F8919',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#FFFFFF',
  'bg-auxiliary-color': '#343A40',

  'bg-circle-color': '#FFFFFF',
  'bg-circle-hover': '#A0BD42',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#A0BD42',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#A0BD42',
  'entry-message-color': '#A0BD42',

  'bg-modal-color': '#343A40',

  'dark-color': '#3F3F3F',

  'text-primary': '#FFFFFF',
  'text-secondary': '#FFFFFF',
  'text-tertiary': '#FFFFFF',
  'text-gray': '#4F4F4F',

  'input-color': '#FFFFFF',
  'bg-input-color': '#FFFFFF',
  'info-color': '#6c757d',
  'disabled-color': '#6c757d',
  'card-color': '#495057',
  'modal-border-color': '#ADB5BD',
  'border-color': '#FFFFFF',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#A0BD42',
  'echeck-bar-color2': '#A0BD42'
}
